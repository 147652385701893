var wipwrp = window.wipwrp || {};

wipwrp.autocomplete = (function($,mzr) {

	if(!window.Awesomplete){
		throw new Error("Oh oh, you have to require Awesomeplete plugin to init wipwrp.autocomplete ...")
	};

/* ----------------- private vars ------------------*/

	var	self				=	{};
	var selector, freewriting, valueinfield;
	var defaults = {
		selector: ".autocomplete",
		freewriting: false,
		valueinfield: false
	}
/* ----------------- public methods ---------------*/

	
	self.init = function(opt) {
		var settings = $.extend({}, defaults, opt );

		if (settings.selector.length > 0) {

			$(settings.selector).each(function(){
				var obj = {};
				obj.$selector = $(this);
				obj.freewriting = settings.freewriting;
				obj.valueinfield = settings.valueinfield;

				if($(this).prop('list')){
					// DATA LIST IN HTML:
					_initSimpleAwesomplete(obj);

				} else if ($(this).data('ajax-list')){
					// DATA LIST IN JSON SERVICE:
					_initJsonAutomplete(obj);

				} else {
					var inptId = $(this).attr('id');
				}
			});
		} //endif
	}

	function _initSimpleAwesomplete(obj){

		var inptId = obj.$selector.attr('id');
		var listId = obj.$selector.attr('list');
		var input = document.getElementById(inptId);
		new Awesomplete(input, {list: document.querySelector(listId)});
	}

	function _initJsonAutomplete(obj) {
		var domElement = obj.$selector[0];
		var jsonSource = obj.$selector.attr('data-ajax-list');
		var labelJsonKey = obj.$selector.attr('data-ajax-label');
		var valueJsonKey = obj.$selector.attr('data-ajax-value');
		var newComplete = new Awesomplete(domElement);

		if(valueJsonKey!==""){
			if (obj.valueinfield === false) {
				var _valInptName = obj.$selector.attr('name')+"_value";
				var _valInpt = '<input type="hidden" id="'+_valInptName+'" name="'+_valInptName+'" />'
				$('#'+_valInptName).remove();
				obj.$selector.parent().append(_valInpt);
			}
		}

		var dataObj = {};
		obj.$selector.on('focus', function(){
			// retrieve data from json
			$.getJSON( jsonSource, function( data ) {
				var labelsArr = [];
				dataObj = data;
				$.map(data.list, function(item, index) {
					labelsArr.push(item[labelJsonKey]);
				});
				// create autocomplete
				newComplete.list = labelsArr;
			});
		});
		// get the selection hook and match from data
		domElement.addEventListener('awesomplete-selectcomplete', function(evt){
			
			var selectedLabel = this.value;
			var relatedValue = $(dataObj.list).filter(function (i,n){
				return n[labelJsonKey]==selectedLabel;
			});

			if(valueJsonKey!==""){
				if (obj.valueinfield === false) {
					try {
						this.value = relatedValue[0][labelJsonKey];
						document.getElementById(this.id+"_value").value = relatedValue[0][valueJsonKey];
						$('#'+this.id+"_value").trigger('change');
					}
					catch(err) {
						console.warn("autocomplete.js - linee 95-97");
					}
				}else{
					this.value = relatedValue[0][valueJsonKey];
				}
			}else{
				this.value = relatedValue[0][labelJsonKey];
			}
		});

		//se devo forzare l'input sui valori passati	
		if (obj.freewriting === false) {
			//SE ESCO DAL CAMPO
			domElement.addEventListener('blur', function(e){
				if (this.value !== "") {
					//VARS
					var field = this;
					var novalue = true;

					if(valueJsonKey!==""){
						if (obj.valueinfield === false) {
							var hiddenfield = document.getElementById(this.id+"_value"),
							$hiddenfield = $('#'+this.id+"_value");

						}
					}

					// SONO USVCITO DAL CAMPO:
					//controllo i valori
					if(dataObj.list) {
						$.each(dataObj.list, function (i) {
							//Se il valore scritto coincide con il valore
							
							// normalizzo quel che scrivo e i valori passati
							var _normInput = field.value.toLowerCase();
							var _normLabel = this[labelJsonKey].toLowerCase();
							var _normValue = this[valueJsonKey].toLowerCase();
							
							if (_normLabel == _normInput || _normValue == _normInput) {
								novalue = false;
								field.value = this[labelJsonKey];
								if (valueJsonKey !== "") {
									if (obj.valueinfield === false) {
										hiddenfield.value = this[valueJsonKey];
										$hiddenfield.trigger('change');
									} else {
										field.value = this[valueJsonKey];
									}
								}
							}
							//Se alla fine del giro non trovo un cazzo
							if (i + 1 == dataObj.list.length) {
								if (novalue == true) {
									field.value = '';
									if (valueJsonKey !== "") {
										if (obj.valueinfield === false) {
											hiddenfield.value = '';
											$hiddenfield.trigger('change');
										}
									}
									field.focus();	
									/*
										var _errormessage = 'Use a suggested value';
										if ($(field).data('errormessage') != undefined) {
											_errormessage = $(field).data('errormessage');
										};
										$(field).attr('placeholder',_errormessage);
									*/
								};
							}
						});
					}
				};
			});
		};

		//verifico se il campo è fillato e ha un relativo hidden, nel caso triggero per compilarlo
		if (domElement.value != "") {
			if (obj.valueinfield === false) {
				$.getJSON( jsonSource, function( data ) {
					var labelsArr = [];
					dataObj = data;
					$.map(data.list, function(item, index) {
						labelsArr.push(item[labelJsonKey]);
					});
					newComplete.list = labelsArr;
					Awesomplete.$.fire(domElement,'awesomplete-selectcomplete');
				});
			}
		};
	}


	return self;

})(jQuery,Modernizr);
